import React from 'react'
import Scheme from '../../assets/images/Esquema.png'
import StoreCheck from '../../assets/images/Partnet_ch_Azul.png'
import './pservices.css'

export default class PartneredServices extends React.Component<any>{
    public render(){
        return(
            <div className="jumbotron jumbotron-650px" id="pservices"> 
                <hr className="pservices"></hr>
                <h1 className="display-4">Partnered Services</h1>
                <div className="pservices-container">
                    <div className="image-container"><img src={Scheme} alt="Scheme" className="trixo-logo"/></div>
                    <div className="ptext-container">
                        <p>Trixo is the <b>cornerstone</b> between your product, 
                        the point of sale and the final consumer. Through this partner, we can offer you 
                        holistic solutions to meet your requirements: form <b>merchandiser</b> and <b>demonstrator</b>
                        services, to point of sale <b>display</b> and <b>brand activations. Trixo is a group partner 
                        company that complements export management operations by making sure to have hands 
                        and eyes on the Business at a local basis.</b></p>
                    </div>
                    <div className="ptext-container">
                        <p>The most <b>cutting-edge-technology</b> in the business! Storecheck is our official partner 
                        app, tailored-made to assist you with your point of sale services. Monitor your 
                        demonstrators and merchandisers performance, analyze your business category, 
                        study prices, and much more!</p>
                    </div>
                    <div className="image-container"><img src={StoreCheck} alt="Scheme" className="storecheck-logo"/></div>
                </div>
            </div>
        )
    }
}