import React from 'react'
import './commitments.css'

export default class Commitments extends React.Component<any>{
    public render(){
        return(
            <div className="jumbotron jumbotron-350px" id="commitments"> 
                <div className="commitment c_1">
                    <div>
                        <h4>With our retail clients</h4>
                        <p>To offer differentiated products to those already existing in the target market. To give them added value in price, quality, and novelty in their respective category.</p>
                    </div>
                </div>
                <div className="commitment c_2">
                    <div>
                        <h4>With our manufacturers</h4>
                        <p>To support their brand in our respective market, committing to focus our attention on their brand, image and competitiveness.</p>
                    </div>
                </div>
                <div className="commitment c_3">
                    <div>
                        <h4>With our consumers</h4>
                        <p>To constantly surprise our audience with innovate and value products.</p>
                    </div>
                </div>
                <div className="commitment c_4">
                    <div>
                        <h4>With our employees</h4>
                        <p>To enrich their life, both personally and professionally, so that they enjoy and learn.</p>
                    </div>
                </div>
            </div>
        )
    }
}