import React from 'react'
import ContactForm from './form'
import './contact.css'

export default class Contact extends React.Component<any>{
    public render(){
        return(
            <div className="jumbotron jumbotron-500px" id="contact"> 
                <div className="panel panel-1">
                    <div>
                        <h1>Contact</h1>
                        <ul>
                            <li><a href="tel:+1-702-605-4471">1-702-605-4471</a></li>
                            <li><a href="mailto:info@fieldsusa.net">info@fieldsusa.net</a></li>
                        </ul>
                    </div>
                </div>
                <div className="panel panel-2">
                    <ContactForm />
                </div>
            </div>
        )
    }
}