import React from 'react'
import './services.css'

export default class Services extends React.Component<any>{
    public render(){
        return(
            <div className="jumbotron jumbotron-650px" id="services"> 
                <h1 className="display-4">Our Services</h1>
                <div className="services-container">
                    <div className="services-3">
                        <div className="service" id="presentation"><span className="image">&nbsp;</span><span>Product presentation and Brand</span></div>
                        <div className="service" id="introduction"><span className="image">&nbsp;</span><span>Product introduction/market</span></div>
                        <div className="service" id="health"><span className="image">&nbsp;</span><span>Product/Health/Registration in each market/permits</span></div>
                    </div>
                    <div className="services-2">
                        <div className="service" id="export"><span className="image">&nbsp;</span><span>Export and import coordination</span></div>
                        <div className="service" id="marketing"><span className="image">&nbsp;</span><span>Marketing / Advertising plan development</span></div>
                    </div>
                </div>
            </div>
        )
    }
}