import React from 'react'
import './Container.css'
import './story.css'


export default class Story extends React.Component<any>{
    public render(){
        return(
            <div className="jumbotron jumbotron-400px" id="story"> 
                <h1 className="display-4">Our Story</h1>
                <div className="text-container">
                    <p>Fields is a state-of-the-art company, born with the purpose of bringing together manufacturers and retailers in today´s global economy. We provide direct service to companies who are looking for a better control of their brands from country of origin to end markets.</p>
                    <p>We started our work in mass consumer goods over 14 years ago, specifically in Mexico and Costa Rica. Today, we have presence in 9 countries across the Americas; serving all main retail chains in the region.</p>
                    <p>We are more than brokers, as we provide added value to the sale and distribution of your brands. Our main goal is to increase your market share, trough the experience and relationships we have built with several commercial channels.</p>
                </div>
            </div>
        )
    }
}