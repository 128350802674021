import React from 'react'
import Header from '../header/Header'
import Container from '../container/Container'
import FieldsLogo from '../../assets/images/Logo_Fields.png'
import Home from '../panels/home'
import Services from '../panels/services'
import Story from '../panels/story'
import PartneredServices from '../panels/pservices'
import Commitments from '../panels/commitments'
import Contact from '../panels/contact'
import Footer from '../footer/footer'
import './App.css'

interface IState {
    isOnTop: boolean;
}

export default class App extends React.Component<any, IState> {
    private sections = [
        {id: "home", sectionText: "Home", container: <Home key="home"/>},
        {id: "services", sectionText: "Our Services", container: <Services key="services"/>},
        {id: "story", sectionText: "Our Story", container: <Story key="story"/>},
        {id: "pservices", sectionText: "Partnered Services", container: <PartneredServices key="pservices"/>},
        {id: "commitments", sectionText: "Our Commitments", container: <Commitments key="commitments"/>},
        {id: "contact", sectionText: "Contact Us", container: <Contact key="contact" />}
    ]
    
    state = {
        isOnTop: true
    }

    constructor(props: any){
        super(props)
        this.handleScroll = this.handleScroll.bind(this)
    }

    scrollPosY : number = 0;

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }

    private handleScroll(){
        this.scrollPosY  = window.scrollY
        console.log(this.scrollPosY)
        if(this.scrollPosY > 1) {
            this.setState({isOnTop: false})
        } else if(this.scrollPosY <= 0) {
            this.setState({isOnTop: true})
        }
    }

    private createContainers(){
        let containers = []
        let length = this.sections != null ? this.sections.length : 0
        for(let i =0 ; i<length;i++){
            containers.push(this.sections[i].container != null ? this.sections[i].container : <Container section={this.sections[i]} key={i}></Container>)
        }
        console.log(containers)
        return containers
    }
    
    public render(){
        return (
            <div className="App">
                <Header links={this.sections} logo={FieldsLogo} onTop={this.state.isOnTop}></Header>
                <div className="navigation-gradient"></div>
                {this.createContainers()}
                <Footer logo={FieldsLogo}/>
            </div>
        )
    }
}
