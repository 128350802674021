import React from 'react'
import './home.css'
import fieldLogo from '../../assets/images/Logo_Fields_White.png'

export default class Home extends React.Component<any>{
    public render(){
        return(
            <div className="jumbotron jumbotron-650px first" id="home">
                <div className="text-container">
                    <img src={fieldLogo} alt="logo"/>
                    <div className="home-text">
                        <p>Your <b>Export Management</b> Comercial Partner</p>
                        <hr className="home"></hr>
                    </div>
                </div>
            </div>
        )
    }
}