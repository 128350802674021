import React from 'react'


export default class ContactForm extends React.Component<any>{
    render(){
        return(
            <div className="container">
                <form action="/backend/index.php" method="post">
                    <div className="row">
                        <div className="col-50">
                            <input type="text" id="name" name="name" placeholder="Name" required/>
                        </div>
                        <div className="col-50">
                            <input type="email" id="email" name="email" placeholder="E-mail" required/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-75">
                            <input type="text" id="subject" name="subject" placeholder="Subject" required/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-75">
                            <textarea id="body" name="body" placeholder="Content"></textarea>
                        </div>
                        <div className="col-75 checkbox">
                            <input type="checkbox" required name="terms" /> I accept the Terms and Conditions
                        </div>
                    </div>
                    <div className="row">
                        <input type="submit" value="Submit" />
                    </div>
                </form>
            </div>
        )
    }
}