import React from 'react'
import './footer.css'

export default class Footer extends React.Component<any>{

    render(){
        return(
            <div className="footer-container">
                <img src={this.props.logo} alt="logo" className="fields_logo_footer"/> &copy; {(new Date().getFullYear())}
            </div>
        )
    }
}