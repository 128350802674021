/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export default class Container extends React.Component<any>{
    public render(){
        return(
            <div className={this.props.section.size} id={this.props.section.id}> 
                {this.props.section.displayText ? <h1 className="display-4">{this.props.section.sectionText}</h1> : ""}
                <p className="lead">This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
                <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
            </div>
        )
    }
}