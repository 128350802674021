/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from "react-scroll"
import './Header.css'


export default class Header extends React.Component<any> {

    public createLinks(){
        let links = []
        let length = this.props.links != null? this.props.links.length : 0
        for(let i =0; i<length;i++){
            links.push(<li className="nav-item" key={i}>
                <Link to={this.props.links[i].id} spy={true} smooth={true} offset={50} duration={1000}>{this.props.links[i].sectionText}</Link>
            </li>)
        }
        return links
    }

    public render() {

        return (
            <nav className={"navbar fixed-top navbar-expand-lg navbar-light bg-light" + (this.props.onTop ? "" : " notOnTop")} >
                <a className="navbar-brand" href="#home"><img src={this.props.logo} alt="logo" className="fields_logo"/></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse justify-content-end" id="navbarToggler">
                    <ul className="navbar-nav mt-lg-0">    
                        {this.createLinks()}
                    </ul>
                </div>
            </nav>
        )
    }
}